import { enumGuessType } from "./Common";
export class Divisions{
    
    constructor(){
        this.divisions = {
            "North": {
                "AFC": {
                    "teams": ["Bengals", "Steelers", "Browns", "Ravens"]
                },
                "NFC": {
                    "teams": ["Packers", "Bears", "Vikings", "Lions"]
                }
            },
            "South": { 
                "AFC": {
                    "teams": ["Titans", "Colts", "Texans", "Jaguars"]
                    },
                "NFC": {
                    "teams": ["Buccaneers", "Saints", "Falcons", "Panthers"]
                    }
                },
            "East": {
                "AFC": {
                    "teams": ["Dolphins", "Bills", "Jets", "Patriots"]
                },
                "NFC": {
                    "teams": ["Eagles", "Giants", "Football Team", "Cowboys"]
                }
            },
            "West": {
                "AFC": {
                    "teams": ["Chiefs", "Chargers", "Broncos", "Raiders"]
                },
                "NFC": {
                    "teams": ["Cardinals", "Rams", "49ers", "Seahawks"]
                }
            }
        }
    }

    getConference(team){
        for(var conference in this.divisions){

            if (this.divisions[conference].AFC.teams.includes(team)) return "AFC";
            else if (this.divisions[conference].NFC.teams.includes(team)) return "NFC";
        }
        return "";
    }

    getDivision(team){
        for(var division in this.divisions){
            if (this.divisions[division].AFC.teams.includes(team) || this.divisions[division].NFC.teams.includes(team)) return division;
        }
        return "";
    }

    compareTeams(teamOne, teamtwo){
        if(this.getDivision(teamOne) === this.getDivision(teamtwo) && this.getConference(teamOne) === this.getConference(teamtwo)) return enumGuessType.CORRECT;
        if(this.getDivision(teamOne) === this.getDivision(teamtwo) && this.getConference(teamOne) !== this.getConference(teamtwo)) return enumGuessType.CLOSE;
        else return enumGuessType.WRONG;
    }
}