<template>
  <div class="huddleGame">
    <div class="container">
      <b-modal v-model="showAnswer" hide-footer  :title=answerModalTitle
        id='answerModal'>
      <h3 class="text-center">{{ game.answer.firstName }} {{game.answer.lastName}} </h3>
      </b-modal>
      <div id='searchForm'>
        <b-input-group size="lg" class="mb-1" >
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input id="searchBox" type="search" :placeholder="guessText"
          v-model="inputSearch" list="filteredPlayers" @change="guessSelect" 
          autocomplete="off"
          :disabled=isGameOver></b-form-input>
          <datalist id="filteredPlayers">
          <option v-for="player in selectedPlayers" :key="player.primaryKey" >{{ player.firstName }} {{player.lastName}}</option>
        </datalist>
        </b-input-group>
      </div>
      <GuessTable :guesses="guesses"/>
    </div>
  </div>

</template>

<script>

import {playerDetails} from '../data/nflPlayersFinal';
import {answers} from '../data/answers';
import GuessTable from './GuessTable.vue';
import {GuessChecker} from '../guessChecker/GuessChecker';
import {enumGuessType} from '../guessChecker/Common';
export default {
  name: 'HuddleGame',
  components: {
    GuessTable
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [{
        name: "description",
        content: this.metaDescription
      }]
    }
  },
  data: function(){
    return {
      players: playerDetails,
      inputSearch: "",
      showAnswer: false,
      isGameOver: false,
      isCorrectAnswer: false,
      game: this.getGame(),
      guesses:[],
      maxGuesses: 8
    }
  },
  computed: {
    metaTitle: function(){
      return "Huddle - The Wordle for NFL Players";
    },
    metaDescription: function(){
      return "Kind of like Wordle, but for NFL players!";
    },
    selectedPlayers: function(){
      if (this.inputSearch.length < 3) { return null; }
      const players = Object.keys(this.players).map(playerId => this.players[playerId]);
      const matchedInputs = players.filter(player => {
        return player.firstName.toLowerCase()
          .startsWith(this.inputSearch.toLowerCase()) ||
          player.lastName.toLowerCase()
          .startsWith(this.inputSearch.toLowerCase()) ||
          `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`
          .startsWith(this.inputSearch.toLowerCase())});
      return matchedInputs.slice(0,7);
    },
    guessText: function(){
      const guessNumber = (this.guesses.length < this.maxGuesses) ? this.guesses.length +1 : this.maxGuesses;
      return `Guess ${guessNumber} of ${this.maxGuesses}`;
    },
    answerModalTitle: function(){
      return (this.isCorrectAnswer)? "You Are Correct!" : "You Were Wrong :(";
    },
  },
  methods: {
    formatCellVariant(guessType){
      if (!guessType) return '';
      if (guessType==enumGuessType.CORRECT) return 'success';
      return (guessType==enumGuessType.CLOSE)? 'warning': 'danger';

    },
    formatGuess(guessPlayer, guessCheckPayload){
      var cellVariants = {};
      var isAbove = {};
      var formattedGuess = guessPlayer;
      formattedGuess['division'] = guessCheckPayload.payloadDivision;
      if (guessCheckPayload.isCorrect){
        cellVariants = {division: 'success', 
          team: 'success',
          position: 'success',
          age: 'success',
          jerseyNum: 'success',
          height: 'success' };
          formattedGuess['_cellVariants'] = cellVariants;
          return formattedGuess;
      }
      const divisionCheck = guessCheckPayload.divisionCheck;
      const divisionVariant = this.formatCellVariant(divisionCheck);
      const teamCheck = guessCheckPayload.teamCheck;
      const teamVariant = this.formatCellVariant(teamCheck);
      const positionCheck = guessCheckPayload.positionCheck;
      const positionVariant = this.formatCellVariant(positionCheck);
      const heightGuessType = guessCheckPayload.heightCheck.guessType;
      const heightVariant = this.formatCellVariant(heightGuessType);
      if (heightGuessType != enumGuessType.CORRECT) isAbove.height = guessCheckPayload.heightCheck.isAbove;
      const ageGuessType = guessCheckPayload.ageCheck.guessType;
      const ageVariant = this.formatCellVariant(ageGuessType);
      if (ageGuessType != enumGuessType.CORRECT) isAbove.age = guessCheckPayload.ageCheck.isAbove;
      const jerseyNumGuessType = guessCheckPayload.jerseyCheck.guessType;
      const jerseyNumVariant = this.formatCellVariant(jerseyNumGuessType);
      if (jerseyNumGuessType != enumGuessType.CORRECT) isAbove.jerseyNum = guessCheckPayload.jerseyCheck.isAbove;

      cellVariants = {division: divisionVariant, 
          team: teamVariant,
          position: positionVariant,
          age: ageVariant,
          jerseyNum: jerseyNumVariant,
          height: heightVariant };
      formattedGuess['_cellVariants'] = cellVariants;
      formattedGuess['isAbove'] = isAbove;
      return formattedGuess;
    },
    guessSelect(e){
      console.log(e);
      const name = e.toLowerCase();
      console.log(name[0]);
      const players = Object.keys(this.players).map(playerId => this.players[playerId]);
      const guessPlayer = players.find(player => `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`===name);
      console.log(guessPlayer);
      const guessPayload = this.game.guessChecker.checkGuess(guessPlayer);
      console.log(guessPayload);
      this.isCorrectAnswer = guessPayload.isCorrect;
      const formattedGuess = this.formatGuess(guessPlayer, guessPayload);
      this.guesses.push(formattedGuess);
      this.isGameOver = this.isCorrectAnswer || this.guesses.length >= this.maxGuesses;
      this.showAnswer = this.isGameOver;
      this.inputSearch = "";
    },
    getTodayAnswer() {
        const startDate = new Date(2022,2,10,0,0,0,0);
        const now = new Date();
        now.setHours(0,0,0,0);
        var dayDiff = now.getTime() - startDate.getTime();
        dayDiff = Math.ceil(dayDiff / 86400000); // divide by milisec in one day
        const playerLength = Object.keys(playerDetails).length;
        const answerIndex = dayDiff % playerLength;
        const todayAnswerIndex = answers[answerIndex];
        const answer = playerDetails[todayAnswerIndex];
        console.log(answer);
        return answer;
    },
    getGame(){
      const answer = this.getTodayAnswer();
      const guessChecker = new GuessChecker(answer, playerDetails);
      return {
        "answer": answer,
        "guessChecker": guessChecker
      };
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#searchForm{
  margin: 2%;
}
</style>
