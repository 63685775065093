import Vue from 'vue'
import VueRouter from 'vue-router'
import HuddleHome from '../views/HuddleHome.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HuddleHome',
    component: HuddleHome
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
