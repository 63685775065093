<template>
  <div class="huddleHome">
    <HuddleGame/>
  </div>
</template>

<script>
// @ is an alias to /src
import HuddleGame from '../components/HuddleGame.vue';

export default {
  name: 'HuddleHome',
  components: {
    HuddleGame
  }
}
</script>
