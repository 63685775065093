<template>
      <div class="GuessTable">
        <b-table hover :items="guesses"  :fields="fields" bordered>
          <template #cell(name)="data">
            <b class="text-info">{{ data.item.firstName }} {{ data.item.lastName }}</b>
          </template>
          <template #cell(age)="data">
            {{ data.item.age }}  
            <b-icon v-if="isAgeAbove(data)==1" icon="arrow-up"></b-icon>
            <b-icon v-if="isAgeAbove(data)==-1" icon="arrow-down"></b-icon>
         </template>
          <template #cell(jerseyNum)="data">
            {{ data.item.jerseyNum }}  
            <b-icon v-if="isJerseyNumAbove(data)==1" icon="arrow-up"></b-icon>
            <b-icon v-if="isJerseyNumAbove(data)==-1" icon="arrow-down"></b-icon>
         </template>
          <template #cell(height)="data">
            {{formatHeight(data)}}  
            <b-icon v-if="isHeightAbove(data)==1" icon="arrow-up"></b-icon>
            <b-icon v-if="isHeightAbove(data)==-1" icon="arrow-down"></b-icon>
         </template>
        </b-table>
      </div>
</template>

<script>


export default {
  name: 'GuessTable',
  components: {
  },
  props: {
    guesses: Array,
  },
  data: function(){
    return {
      fields: [{ key: 'name', label: 'Full Name' },
      {key: 'division', 'label': 'Division' },
      {key: 'team', 'label': 'Team' },
      { key: 'position', label: 'Position' },
      {key: 'age', label: 'Age'},
      {key: 'jerseyNum', label: 'Jersey #'},
      {key: 'height', 'label': 'Height'}],
    }
  },
  computed: {

  },
  methods: {
    // track(){
    //   this.$gtag.pageview({ page_path: '/home' })
    // }
    formatHeight(data){
        var inches = data.item.height;
        var feet = Math.floor(inches / 12);
        inches %= 12;
        return `${feet} ft ${inches} in`
    },
    isAbove(data, key){
        if (!('isAbove' in data.item )) return 0;
        if (key in data.item.isAbove) return (data.item.isAbove[key])? 1 : -1;
        return 0;
    },
    isAgeAbove(data){
        return this.isAbove(data, 'age');
    },
    isJerseyNumAbove(data){
        return this.isAbove(data, 'jerseyNum');
    },
    isHeightAbove(data){
        return this.isAbove(data, 'height');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
