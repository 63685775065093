import { enumGuessType } from "./Common";
export class PositionGroup{

    constructor(){
        this.positionGroups = {
                "Defence": {
                    "positions" : ["RE", "LE", "DT", "ROLB", "LOLB", "MLB", "CB", "SS", "FS"]
                },
                "Offence": {
                    "positions" : ["QB", "WR", "HB", "TE", "C", "LG", "LT", "RG", "RT", "FB"]
                },
                "Special Teams": {
                    "positions": ["K", "P"]
                }
            }
    }

    getUnit(playerPosition){
         for (var unit in this.positionGroups){
            if(this.positionGroups[unit].positions.includes(playerPosition)) return unit
         }
        return "";
    }


    comparePositions(playerOnePosition, playerTwoPosition){
        if (playerOnePosition === playerTwoPosition) return enumGuessType.CORRECT;
        else if (this.getUnit(playerOnePosition) === this.getUnit(playerTwoPosition)) return enumGuessType.CLOSE
        else return enumGuessType.WRONG;
    }
}