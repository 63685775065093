import { enumGuessType } from "./Common";
import { PositionGroup } from "./PositionGroup";
import { Divisions } from "./Divisions";
export class GuessChecker {

    constructor(answer, players){
        this.answer = answer;
        this.players = players;
        this.positionGroups = new PositionGroup();
        this.divisions = new Divisions();
        this.HEIGHT_THRESHOLD = 3;
        this.AGE_THRESHOLD = 5;
        this.JERSEY_NUMBER_THRESHOLD = 5;
    }

    checkGuess(guessPayload){
        console.log(`guess payload: ${guessPayload}`);
        const playerId = guessPayload.primaryKey;
        const payloadsDivision = this.divisions.getDivision(guessPayload.team);
        if(this.answer.primaryKey == playerId){
            return {
                "isCorrect" : true,
                "payloadDivision": payloadsDivision 
            };
        }


        else{
            return {
                "isCorrect" : false,
                "teamCheck": this.checkTeam(this.answer, guessPayload),
                "ageCheck": this.checkAge(this.answer, guessPayload),
                "heightCheck": this.checkHeight(this.answer, guessPayload),
                "jerseyCheck": this.checkJerseyNumber(this.answer, guessPayload),
                "positionCheck": this.checkPosition(this.answer, guessPayload),
                "divisionCheck": this.checkDivision(this.answer, guessPayload),
                "payloadDivision": payloadsDivision
            }
        }

    }

    checkHeight(answer, playerInfo){
      return this.compareNumeric(answer.height, playerInfo.height, this.HEIGHT_THRESHOLD);
    }

    checkAge(answer, playerInfo){
        return this.compareNumeric(answer.age, playerInfo.age, this.AGE_THRESHOLD);
    }

    checkJerseyNumber(answer, playerInfo){
        return this.compareNumeric(answer.jerseyNum, playerInfo.jerseyNum, this.JERSEY_NUMBER_THRESHOLD);
    }


    compareNumeric(answer, guess, threshold){
        const diff = answer - guess;
        var guessType = enumGuessType.WRONG;
        var isAbove = diff > 0;

        if (diff == 0) guessType = enumGuessType.CORRECT
        else if (Math.abs(diff) <= threshold){
            guessType = enumGuessType.CLOSE
        }

        return {
            "guessType": guessType,
            "isAbove": isAbove
        }
    }

    checkPosition(answer, guess){
        return this.positionGroups.comparePositions(answer.position, guess.position);
    }


    checkDivision(answer, guess){
        return this.divisions.compareTeams(answer.team, guess.team);
    }

    checkTeam(answer, guess){
        return (answer.team === guess.team)? enumGuessType.CORRECT : enumGuessType.WRONG;
    }
}